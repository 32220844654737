import React from 'react';

export const BackToIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#68717B" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0175 9.56547L17.7522 6.24061C17.5934 6.08448 17.3808 5.99808 17.1601 6.00003C16.9394 6.00199 16.7283 6.09213 16.5722 6.25105C16.4161 6.40997 16.3276 6.62495 16.3257 6.84969C16.3237 7.07443 16.4086 7.29095 16.5619 7.45261L19.8272 10.7775C19.9242 10.8782 20.009 10.9904 20.0798 11.1118C20.0671 11.1118 20.057 11.1049 20.0444 11.1049L6.84179 11.1323C6.61853 11.1323 6.40442 11.2226 6.24655 11.3834C6.08869 11.5441 6 11.7621 6 11.9895C6 12.2168 6.08869 12.4348 6.24655 12.5956C6.40442 12.7563 6.61853 12.8466 6.84179 12.8466L20.0394 12.8192C20.0629 12.8192 20.0823 12.8072 20.105 12.8055C20.0303 12.9505 19.9357 13.0838 19.8239 13.2015L16.5586 16.5263C16.4782 16.6054 16.414 16.7 16.3699 16.8045C16.3258 16.9091 16.3026 17.0216 16.3016 17.1354C16.3006 17.2492 16.3219 17.3621 16.3643 17.4674C16.4066 17.5728 16.4691 17.6685 16.5481 17.7489C16.6272 17.8294 16.7211 17.8931 16.8246 17.9362C16.9281 17.9793 17.0389 18.001 17.1507 18C17.2624 17.999 17.3729 17.9753 17.4756 17.9304C17.5783 17.8855 17.6712 17.8202 17.7488 17.7383L21.0141 14.4135C21.6454 13.7705 22 12.8986 22 11.9895C22 11.0803 21.6454 10.2084 21.0141 9.56547H21.0175Z"
    />
    <path
      d="M8.125 20.3333H6.375C5.67881 20.3333 5.01113 20.0699 4.51884 19.6011C4.02656 19.1323 3.75 18.4964 3.75 17.8333V6.16667C3.75 5.50363 4.02656 4.86774 4.51884 4.3989C5.01113 3.93006 5.67881 3.66667 6.375 3.66667H8.125C8.35706 3.66667 8.57962 3.57887 8.74372 3.42259C8.90781 3.26631 9 3.05435 9 2.83333C9 2.61232 8.90781 2.40036 8.74372 2.24408C8.57962 2.0878 8.35706 2 8.125 2H6.375C5.2151 2.00132 4.10311 2.44073 3.28294 3.22185C2.46277 4.00296 2.00139 5.062 2 6.16667L2 17.8333C2.00139 18.938 2.46277 19.997 3.28294 20.7782C4.10311 21.5593 5.2151 21.9987 6.375 22H8.125C8.35706 22 8.57962 21.9122 8.74372 21.7559C8.90781 21.5996 9 21.3877 9 21.1667C9 20.9457 8.90781 20.7337 8.74372 20.5774C8.57962 20.4211 8.35706 20.3333 8.125 20.3333Z"
    />
  </svg>
);
