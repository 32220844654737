import React, { useEffect } from 'react';
import moment from 'moment';
import { some } from 'lodash';
import { useMutation } from 'react-query';
import { push } from 'connected-react-router';

import { getCookie } from 'utils/cookie';
import { useAppDispatch } from 'utils/hooks';
import axiosInstance from 'modules/api-client/request';
import { PathCreator, RoutePaths } from 'routing/constants';
import { setAuthCookies } from 'pages/auth/set-auth-cookies';
import { refreshTokenRequest } from 'modules/api-requests/auth';

import type { AxiosError, AxiosRequestConfig } from 'axios';

export const AxiosInterceptors = () => {
  const dispatch = useAppDispatch();

  const { mutateAsync } = useMutation('refreshToken', refreshTokenRequest, {
    onSuccess: (data) => {
      setAuthCookies(data);
    },
  });

  // check token time every request
  useEffect(() => {
    axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      const expiredCookie = getCookie('ExpiresIn');
      const refreshTokenCookie = getCookie('RefreshToken');
      if (Number(expiredCookie) && refreshTokenCookie && config.url !== '/customers/signin') {
        const expiredTime = moment.unix(Number(expiredCookie));
        if (moment().diff(expiredTime, 'minutes') > -5) {
          const refreshData = await mutateAsync({ refreshToken: refreshTokenCookie });
          const { TokenType, AccessToken, IdToken } = refreshData;

          config.headers = {
            ...config.headers,
            Authorization: `${TokenType} ${IdToken}`,
          };

          if (
            some(
              ['/customers/change-password', '/customers/update-attributes', '/customers/me'],
              (compareURL) => config.url && config.url?.indexOf(compareURL) >= 0
            )
          ) {
            config.headers.AccessToken = AccessToken;
          }
        }
      }

      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (
          error.response?.status === 401 ||
          error.message === 'Unauthorized' ||
          error.message === 'The incoming token has expired' ||
          error.message === 'Access Token has expired' ||
          error.message === 'Invalid Access Token' ||
          error.message === 'Invalid Refresh Token'
        ) {
          dispatch(push(PathCreator[RoutePaths.AUTH_LOGOUT].getUrl()));
        }

        return Promise.reject(error);
      }
    );
  }, [dispatch, mutateAsync]);

  return <React.Fragment />;
};

