export interface IThemeColors {
  white: string;
  red: string;
  primary: string;
  primaryWhite: string;
  text: string;
  blockStroke: string;
  lightGrey: string;
  darkGrey: string;
  mediumDarkGrey: string;
  middleGrey: string;
  disabledText: string;
  grey: string;
  background: string;
  backgroundAccent: string;
}

export const colorPalette: IThemeColors = {
  white: '#ffffff',
  red: '#FF6960',
  primary: '#38A5FF',
  primaryWhite: '#66BAFF',
  blockStroke: 'rgba(192, 212, 227, 0.4)',
  text: '#3E444A',
  lightGrey: '#ECF2F7',
  grey: '#C0D4E3',
  darkGrey: '#68717B',
  mediumDarkGrey: '#AFB7BF',
  middleGrey: '#e3eaf2',
  disabledText: '#94979B',
  background: '#f0f2f5',
  backgroundAccent: '#E7EEF4',
};
