import { createAction, createReducer } from '@reduxjs/toolkit';

import type { IConfigState } from './types';

export const configInitState: IConfigState = {
  layout: {
    shrink: false,
  }
};

export const configActions = {
  setLayoutAction: createAction<typeof configInitState.layout>('config/setLayout'),
  resetConfigAction: createAction('config/reset'),
};

export const configReducer = createReducer(
  configInitState,
  (builder) => {
    builder
      .addCase(configActions.setLayoutAction, (state, { payload }) => ({
        ...state,
        layout: payload,
      }))
      .addCase(configActions.resetConfigAction, () => configInitState)
  },
);
