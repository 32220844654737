import Col from 'antd/es/col';
import styled from 'styled-components';

import { Text18 } from 'components/layout/text';
import { PrimaryButton } from 'components/layout/button';
import { CourseImage } from 'components/course-information/elements';
import { EXTRA_LARGE_VIEWPORT, MOBILE_VIEWPORT } from 'modules/theme/config';

export const CourseImageBackground = styled(CourseImage)`
  padding: 0;
  height: 100%;
  border-radius: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const LogoText = styled.div`
  position: absolute;
  bottom: 176px;
  width: 100%;
  padding: 0 64px;
  font-size: 72px;
  font-weight: 700;
  height: 88px;
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    bottom: 56px;
    padding: 0 48px;
    font-size: 56px;
    line-height: 72px;
  }
`;

export const AuthFormCol  = styled(Col)`
 &.ant-col {
  padding: 20vh 0 72px 0;
  min-height: 100vh;
 }
`;

export const AbsoluteLayout = styled.div`
  position: absolute;
  padding: 24px 36px;
  z-index: 2;
  top: 0;
  width: 100%;

  @media (max-width: ${MOBILE_VIEWPORT}px) {
    padding: 32px 14px;
  }
`;

export const AuthFormContainer = styled.div`
  margin: 0 auto;
  width: 400px;

  .ant-input-affix-wrapper {
    border-color: ${({ theme }) => theme.colors.grey};
  }

  .ant-input-affix-wrapper > input.ant-input {
    border-radius: 0;
  }

  .ant-input-prefix {
    margin-right: 8px;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    width: 320px;
  }
`;

export const AuthTitle = styled.div`
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 12px;
  font-weight: 700;
  text-align: center;
  
  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const AuthDescriptionText = styled(Text18)`
  text-align: center;
  margin-bottom: 16px;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 8px;

  &.ant-btn-primary {
    border-radius: 12px;
    height: 32px;

    .ant-btn-lg {
      height: 40px;
    }
  }
`;
