import axios from 'axios';
import some from 'lodash/some';
import { makeResponse } from '../response';
import { getCookie } from 'utils/cookie';

import type { AxiosRequestConfig } from 'axios';

export const serverHost = process.env.REACT_APP_SERVER_HOST;

const axiosInstance = axios.create({
  baseURL: serverHost,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  withCredentials: false,
});

axiosInstance.interceptors.response.use(makeResponse, (error) => {
  if (error.response.status === 500) {
    return Promise.reject({
      data: error.response.data,
      statusCode: 500,
    });
  }

  return Promise.reject(error.response.data);
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  const TokenType = getCookie('TokenType');
  const AccessToken = getCookie('AccessToken');
  const idTokenCookie = getCookie('IdToken');

  config.headers =
    TokenType && idTokenCookie
      ? { ...config.headers, Authorization: `${TokenType} ${idTokenCookie}` }
      : { ...config.headers };

  if (
    some(
      ['/customers/change-password', '/customers/update-attributes', '/customers/me'],
      (compareURL) => config.url && config.url?.indexOf(compareURL) >= 0
    ) &&
    AccessToken
  ) {
    config.headers.AccessToken = AccessToken;
  }

  return config;
});

export default axiosInstance;

