import { createSelector } from 'reselect';

import type { RootState } from 'modules/store/types';

export const getConfigStateSelector = (state: RootState) => state.config;

export const getConfigLayoutSelector = createSelector(
  getConfigStateSelector,
  (state) => state.layout,
);
