import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { LoadingStatusEnum } from '../types';
import { getCourseById } from 'modules/api-requests/course';

import type { ICourseState } from './types';

export const fetchCourseAction = createAsyncThunk('course/get', getCourseById);

const courseInitState: ICourseState = {
  course: undefined,
  loading: LoadingStatusEnum.IDLE,
};

export const courseReducer = createReducer(
  courseInitState,
  (builder) => {
    builder
      .addCase(fetchCourseAction.rejected, (state) => ({
        ...state,
        loading: LoadingStatusEnum.FAILED,
      }))
      .addCase(fetchCourseAction.fulfilled, (state, { payload }) => ({
        ...state,
        course: payload,
        loading: LoadingStatusEnum.SUCCESS,
      }))
      .addCase(fetchCourseAction.pending, (state) => ({
        ...state,
        loading: LoadingStatusEnum.LOADING,
      }))
  },
);
