export const DateFormat = 'MM-DD-YYYY';

export const DateTimeFormat = 'H:mm A [\r\n] DD-MM-YYYY';

export const TimeFormat = 'h:mm A';
export const ServerTimeFormat = 'HH:mm';

export const SeasonDateFormat = 'MM.YY';

export const ServerDateFormat = 'YYYY-MM-DD';
