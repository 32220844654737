import { createAction, createReducer } from '@reduxjs/toolkit';
import { SystemRolesEnum } from './types';

import type { IProfileState } from './types';

export const profileInitState: IProfileState = {
  role: SystemRolesEnum.USER,
};

export const profileActions = {
  setFields: createAction<Partial<IProfileState>>('auth/setFields'),
  resetFields: createAction('auth/resetFields'),
};

export const profileReducer = createReducer(
  profileInitState,
  (builder) => {
    builder
      .addCase(profileActions.setFields, (state, { payload }) => ({
        ...state,
        ...payload,
      }))
      .addCase(profileActions.resetFields, () => profileInitState)
  },
);
