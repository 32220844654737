import 'moment';
import momentTimezone from 'moment-timezone';
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { DateTimeFormat } from 'utils/constants';
import { LoadingStatusEnum } from 'models/types';
import { returnPaginationRequest } from '../helpers';
import { PathCreator, RoutePaths } from 'routing/constants';
import { getUserReservationsRequest } from 'modules/api-requests/reservations';

import type { IListMeta } from 'models/types';
import type { IReservation } from 'modules/api-requests/reservations/types';

const pagePath = PathCreator[RoutePaths.PROFILE_BOOKINGS].path;

export const fetchCourseReservationAction = createAsyncThunk(
  'reservations/get',
  (timeZone: string, thunkAPI) => {
    return returnPaginationRequest<IReservation[], IListMeta>({
      asyncRequest: (params) => getUserReservationsRequest(params),
      thunkAPI,
      pagePath,
      asyncRequestParams: {},
    });
  },
);

interface ICourseReservationState {
  items: IReservation[];
  loading: LoadingStatusEnum;
  error: unknown;
}

const courseReservationsInitState: ICourseReservationState = {
  items: [],
  loading: LoadingStatusEnum.IDLE,
  error: undefined,
};

export const reservationsReducer = createReducer(
  courseReservationsInitState,
  (builder) => {
    builder
      .addCase(fetchCourseReservationAction.rejected, (state, { payload }) => ({
        ...state,
        loading: LoadingStatusEnum.FAILED,
        error: payload,
      }))
      .addCase(fetchCourseReservationAction.fulfilled, (state, { payload, meta }) => (
        {
          items: payload.map(item => ({
            ...item,
            createdAt: momentTimezone.utc(item.createdAt).tz(meta.arg).format(DateTimeFormat),
            playersCount: item.players.length,
          })),
          error: undefined,
          loading: LoadingStatusEnum.SUCCESS,
        }
      ))
      .addCase(fetchCourseReservationAction.pending, (state) => ({
        ...state,
        loading: LoadingStatusEnum.LOADING,
      }));
  },
);
