import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistReducer,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import { authReducer } from 'models/auth';
import { configReducer } from 'models/config';
import { courseReducer } from 'models/course';
import { profileReducer } from 'models/profile';
import { bookingReducer } from 'models/booking';
import { tableMetaReducer } from 'models/table-meta';
import { reservationsReducer } from 'models/reservations';
import { routingMiddleware, routerReducer } from 'models/router';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['booking', 'profile', 'config'],
}

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  router: routerReducer,
  booking: bookingReducer,
  profile: profileReducer,
  course: courseReducer,
  tableMeta: tableMetaReducer,
  reservations: reservationsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat([routingMiddleware]),
});
