import React from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Spin from 'antd/es/spin';
import { useHistory } from 'react-router';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { getCookie } from 'utils/cookie';
import { useAppSelector } from 'utils/hooks';
import { AuthSpinContainer } from './elements';
import { PathCreator, RoutePaths } from 'routing/constants';
import { getConfigLayoutSelector } from 'models/config/selectors';
import { TopBarSuspense } from 'components/suspense/top-bar-suspense';
import { AbsoluteLayout, AuthFormCol, CourseImageBackground } from '../elements';

import type { Gutter } from 'antd/es/grid/row';

const formGutter: Gutter = { xs: 24, sm: 24, xl: 12 };

export const AuthSuspense = () => {
  const breakpoints = useBreakpoint();
  const hasToken = Boolean(getCookie('RefreshToken'));
  const useMobileVersion = !breakpoints.xl;
  const { location } = useHistory();
  const { pathname } = location;
  const configLayout = useAppSelector(getConfigLayoutSelector);

  return (
    <>
      {!hasToken && (
        <Row>
          {!useMobileVersion && pathname !== PathCreator[RoutePaths.BOOKING_LIST].getUrl() ? (
            <Col span={12}>
              <CourseImageBackground $shrink={configLayout.shrink} $backgroundSrc="" alt="" />
            </Col>
          ) : null}

          <AuthFormCol {...formGutter}>
            <AuthSpinContainer>
              <Spin size="large" />
            </AuthSpinContainer>
          </AuthFormCol>
        </Row>
      )}

      <AbsoluteLayout>
        <TopBarSuspense />
      </AbsoluteLayout>
    </>
  );
};

