import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PathCreator, RoutePaths } from './constants';
import { lazyWithRetry } from 'utils/lazy-with-reload';

const SurveyPage = lazyWithRetry(() => import('pages/survey'));

export const OutsideRoutes = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route path={PathCreator[RoutePaths.SURVEY].path} component={SurveyPage} exact />
    </Switch>
  </Suspense>
);
