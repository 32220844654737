import Drawer from 'antd/es/drawer';
import Divider from 'antd/es/divider';
import styled from 'styled-components';

import { Header4 } from '../headers';
import { EXTRA_LARGE_VIEWPORT, MOBILE_VIEWPORT } from 'modules/theme/config';

export const TopBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px 24px;
  border-radius: 24px;
  filter: drop-shadow(0px 8px 16px rgba(104, 113, 123, 0.07));
  z-index: 999;

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    padding: 18px 16px;
    border-radius: 16px;
  }

  @media (max-width: ${MOBILE_VIEWPORT}px) {
    padding: 14px 16px;
    border-radius: 8px;
  }
`;

export const HorizontalTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const GolfCourseContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-shrink: 1;
  margin-right: 16px;
`;

export const GolfCourseLogo = styled.img`
  height: 56px;
  min-width: 56px;
  border-radius: 8px;

  &:not([src]) {
    font-size: 0;
    position: relative;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    height: 44px;
    min-width: 44px;
  }

  @media (max-width: ${MOBILE_VIEWPORT}px) {
    height: 24px;
    min-width: 24px;
  }
`;

export const GolfCourseTitle = styled(Header4)`
  margin: 0 0 0 32px;
  font-weight: 600;

  a {
    text-decoration: none;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    margin: 0 0 0 8px;
    font-size: 18px;
  }

  @media (max-width: ${MOBILE_VIEWPORT}px) {
    font-size: 16px;
  }
`;

export const TopMenu = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  > svg {
    cursor: pointer;

    &:hover {
      transition: fill 0.3s linear;
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: ${MOBILE_VIEWPORT}px) {
    gap: 16px;
    > svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const TopMenuItem = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  margin: 0 16px;
  color: ${({ theme }) => theme.colors.darkGrey};

  a {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.darkGrey};
  }

  a,
  a:hover {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  svg {
    height: 20px;
    width: auto;
    margin-right: 8px;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    font-size: 14px;
    margin: 0 8px;
    svg {
      height: 14px;
      margin-right: 4px;
    }
  }
`;

export const MenuDivider = styled(Divider)`
  &.ant-divider-vertical {
    height: 24px;
    margin: 0 16px;
    border-left-color: ${({ theme }) => theme.colors.grey};

    @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
      margin: 0 8px;
    }
  }
`;

export const MobileMenuDrawer = styled(Drawer)`
  .ant-menu-root.ant-menu-vertical,
  .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right,
  .ant-menu-root.ant-menu-inline {
    box-shadow: none;
    border: 0;
    margin-top: 0;
    padding: 0 12px;
    overflow: hidden;
    box-sizing: content-box;
    transition: max-height 0.3s ease-out, padding 0.3s linear, margin-top 0.3s ease-out;
  }

  .ant-menu-title-content,
  .ant-menu-title-content a {
    display: inline-flex;
    align-items: center;
    gap: 12px;

    &:hover {
      text-decoration: none;
    }
  }

  .ant-menu-item-divider {
    margin: 4px 0;
  }

  a {
    text-decoration: none;
  }
`;

