import React from 'react';
import { colorPalette } from 'modules/theme/colorPalettes';

import type { ISVGElement } from '../types';

export const MobileMenuIcon = (props: ISVGElement) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={colorPalette.text} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1803_15159)">
      <path
        d="M23 10.9995H1C0.447715 10.9995 0 11.4472 0 11.9995C0 12.5518 0.447715 12.9995 1 12.9995H23C23.5523 12.9995 24 12.5518 24 11.9995C24 11.4472 23.5523 10.9995 23 10.9995Z"
      />
      <path
        d="M23 4.00049H1C0.447715 4.00049 0 4.4482 0 5.00049C0 5.55277 0.447715 6.00048 1 6.00048H23C23.5523 6.00048 24 5.55277 24 5.00049C24 4.4482 23.5523 4.00049 23 4.00049Z"
      />
      <path
        d="M23 18H1C0.447715 18 0 18.4477 0 19C0 19.5523 0.447715 20 1 20H23C23.5523 20 24 19.5523 24 19C24 18.4477 23.5523 18 23 18Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1803_15159">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
