import type { ValueOf } from 'routing/constants';
import type { IGetCurrentUserResponse } from 'modules/api-requests/auth/types';

export enum SystemRolesEnum {
  USER = 'user',
  ADMIN = 'admin',
  MANAGER = 'manager',
}

export type SystemRolesType = ValueOf<SystemRolesEnum>;

export interface IProfileState extends Partial<IGetCurrentUserResponse> {
  role: SystemRolesType;
}
