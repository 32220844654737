import React, { HTMLProps } from 'react';

import { StyledSkeleton } from './elements';

interface ISkeleton extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as'> {
  $width?: number | string;
  $height?: number | string;
}

export const Skeleton = ({ $width = '100%', $height = '100%', ...props }: ISkeleton): React.ReactElement => (
  <StyledSkeleton {...props} style={{ height: $height, width: $width }} />
);
