import isNil from 'lodash/isNil';
import Button from 'antd/es/button';
import styled from 'styled-components';
import { EXTRA_LARGE_VIEWPORT } from 'modules/theme/config';

import type { HTMLProps } from 'react';

interface ICourseImage extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as'> {
  $shrink: boolean;
  $height?: number;
  $backgroundSrc: string;
}

export const CourseImage = styled.div<ICourseImage>`
  display: block;
  border-radius: 24px;
  width: 100%;
  height: 0;
  background-size: contain;
  padding-top: ${({ $shrink, $height }) => !isNil($height) && $shrink ? `${$height}px` : '150%'};
  filter: drop-shadow(0px 8px 16px rgba(104, 113, 123, 0.07));
  background: linear-gradient(rgba(56, 165, 255, 0) 0%, rgb(59 59 59 / 40%) 85.5%), url(${({ $backgroundSrc }) => $backgroundSrc}) no-repeat;
  transition: padding-top .266s ease-out;
  transition-delay: ${({ $height, $shrink }) => !isNil($height) && $shrink ? '0' : '0.266s'};

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    padding-top: ${({ $height, $shrink }) => !isNil($height) && $shrink ? `${$height}px` : '272%'};
    background-position-x: 30%;
    border-radius: 16px;
  }
`;

export const CourseInformationContainer = styled.div`
  position: relative;

  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: white;
    }
  }
`;

interface ICourseInformationBlock {
  $height?: number;
  $shrink?: boolean;
}

export const CourseInformationBlock = styled.div<ICourseInformationBlock>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  color: white;
  bottom: 52px;
  left: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  transition: font-size 0.5s ease-out;
  transition-property: font-size, bottom, margin-top;
  transition-delay: ${({ $shrink }) => $shrink ? '0' : '0.532s'};

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    font-size: 14px;
    bottom: 44px;
  }

  ${({ $height = 230, $shrink }) => $height <= 130 && $shrink ? `
      font-size: 12px;
      bottom: 42px;

      .item {
        margin-top: 8px;
      }

      .item.svg {
        flex: 0 0 12px;
      }

      @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
        font-size: 8px;
        bottom: 20px;

        .item {
          margin-top: 4px;
        }

        .item.svg {
          flex: 0 0 12px;
        }
      }
     ` : null
};
`;

export const CourseInformationItem = styled.div`
  display: flex;
  max-width: 70%;
  cursor: pointer;
  margin-top: 24px;
  align-items: center;

  svg {
    flex: 0 0 24px;
    margin-right: 16px;
    display: inline-flex;
  }

  @media (max-width: ${EXTRA_LARGE_VIEWPORT}px) {
    margin-top: 8px;

    svg {
      flex: 0 0 18px;
      margin-right: 8px;
    }
  }
`;

export const ExpandButton = styled(Button)`
  &.ant-btn, &.ant-btn:not([disabled]):active {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: transparent;
    color: white;
    border-color: white;
    z-index: 3;
  }
`;
