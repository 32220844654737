import React from 'react';
import { Link } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import {
  TopMenu,
  MenuDivider,
  TopMenuItem,
  TopBarContainer,
  GolfCourseTitle,
  HorizontalTopBar,
  GolfCourseContainer,
} from 'components/layout/top-bar/elements';

import { Skeleton } from 'components/skeleton';
import { CourseName, SkeletonButton } from './elements';
import { PathCreator, RoutePaths } from 'routing/constants';
import { courseWebsite } from 'modules/api-requests/course/constants';
import { MobileMenuIcon } from 'components/icons/menu/mobile-menu-icon';
import { UnauthorizedContainer } from 'components/layout/profile-menu/elements';

import { PhoneIcon } from 'components/icons/phone-icon';
import { BackToIcon } from 'components/icons/back-to-icon';

export const TopBarSuspense = () => {
  const breakpoints = useBreakpoint();
  const useMobileVersion = !breakpoints.xl;

  return (
    <TopBarContainer>
      <HorizontalTopBar>
        <GolfCourseContainer>
          <Skeleton $height={40} $width={40} />

          <GolfCourseTitle>
            <CourseName active />
          </GolfCourseTitle>
        </GolfCourseContainer>

        <TopMenu>
          {useMobileVersion ? (
            <MobileMenuIcon />
          ) : (
            <>
              <TopMenuItem>
                <PhoneIcon /> Contact Us
              </TopMenuItem>
              <TopMenuItem>
                <a href={courseWebsite}>
                  <BackToIcon /> Back to the course website
                </a>
              </TopMenuItem>
              <MenuDivider type="vertical" />
            </>
          )}

          {breakpoints.md ? (
            <UnauthorizedContainer>
              <Link to={PathCreator[RoutePaths.AUTH_LOGIN].getUrl()}>Log In</Link>

              <SkeletonButton active />
            </UnauthorizedContainer>
          ) : null}
        </TopMenu>
      </HorizontalTopBar>
    </TopBarContainer>
  );
};

