export type ValueOf<T> = T[keyof T];
export type RoutePathsType = ValueOf<typeof RoutePaths>;
export type PathCreatorValue = {
  path: string;
  getUrl: (args?: any) => string;
};

export type PathCreatorType = Record<RoutePathsType, PathCreatorValue>;

export enum RoutePaths {
  AUTH_LOGIN = 'auth_login',
  AUTH_SIGNUP = 'auth_signup',
  AUTH_LOGOUT = 'auth_logout',
  AUTH_CONFIRM_EMAIL = 'auth_confirm_email',
  AUTH_CHANGE_PASSWORD = 'auth_change_password',
  AUTH_RECOVERY_PASSWORD = 'auth_recovery_password',
  BOOKING_LIST = 'booking_list',
  BOOKING_DETAILS = 'booking_details',
  BOOKING_CHECKOUT = 'booking_checkout',
  BOOKING_CONFIRMATION = 'booking_confirmation',
  PROFILE = 'profile',
  PROFILE_BOOKINGS = 'profile_bookings',
  PROFILE_WAITLIST = 'profile_waitlist',
  PROFILE_OFFERS = 'profile_offers',
  DYNAMIC_PRICING = 'dynamic_pricing',
  CONTACT_US = 'contact_us',
  PRIVACY_POLICY = 'privacy-policy',
  OUTSIDE = 'outside',
  SURVEY = 'survey',
  BOOKING_CANCELLATION = 'booking_cancellation',
}

export interface IBookingDetailsParams {
  date: string;
  time: string;
  rate: string;
  offer: string;
}

export interface IBookingCheckoutParams extends IBookingDetailsParams {
  bookingId: string;
}

export const PathCreator: PathCreatorType = {
  [RoutePaths.AUTH_LOGIN]: {
    path: '/login',
    getUrl: () => '/login',
  },
  [RoutePaths.AUTH_SIGNUP]: {
    path: '/signup',
    getUrl: () => '/signup',
  },
  [RoutePaths.AUTH_LOGOUT]: {
    path: '/logout',
    getUrl: () => '/logout',
  },
  [RoutePaths.AUTH_CHANGE_PASSWORD]: {
    path: '/update-password',
    getUrl: () => '/update-password',
  },
  [RoutePaths.AUTH_RECOVERY_PASSWORD]: {
    path: '/forgot-password',
    getUrl: () => '/forgot-password',
  },
  [RoutePaths.AUTH_CONFIRM_EMAIL]: {
    path: '/signup/confirmation',
    getUrl: () => '/signup/confirmation',
  },
  [RoutePaths.BOOKING_LIST]: {
    path: '/',
    getUrl: () => '/',
  },
  [RoutePaths.PROFILE]: {
    path: '/profile',
    getUrl: () => '/profile',
  },
  [RoutePaths.PROFILE_BOOKINGS]: {
    path: '/profile/my-bookings',
    getUrl: () => '/profile/my-bookings',
  },
  [RoutePaths.PROFILE_WAITLIST]: {
    path: '/profile/waitlist',
    getUrl: () => '/profile/waitlist',
  },
  [RoutePaths.PROFILE_OFFERS]: {
    path: '/profile/offers',
    getUrl: () => '/profile/offers',
  },
  [RoutePaths.DYNAMIC_PRICING]: {
    path: '/dynamic-pricing',
    getUrl: () => '/dynamic-pricing',
  },
  [RoutePaths.CONTACT_US]: {
    path: '/contact-us',
    getUrl: () => '/contact-us',
  },
  [RoutePaths.PRIVACY_POLICY]: {
    path: '/privacy-policy',
    getUrl: () => '/privacy-policy',
  },
  [RoutePaths.BOOKING_CONFIRMATION]: {
    path: '/booking/:bookingId/confirmation',
    getUrl: ({ bookingId }: IBookingCheckoutParams) => `/booking/${bookingId}/confirmation`,
  },
  [RoutePaths.BOOKING_DETAILS]: {
    path: '/booking/:date/:time/:rate/details/:offer?',
    getUrl: ({ date, time, rate, offer }: IBookingDetailsParams) =>
      `/booking/${date}/${time}/${rate}/details/${offer ?? ''}`,
  },
  [RoutePaths.BOOKING_CHECKOUT]: {
    path: '/booking/:date/:time/:rate/:bookingId/checkout',
    getUrl: ({ date, time, rate, bookingId }: IBookingCheckoutParams) =>
      `/booking/${date}/${time}/${rate}/${bookingId}/checkout`,
  },
  [RoutePaths.OUTSIDE]: {
    path: '/OUTSIDE',
    getUrl: () => '/outside',
  },
  [RoutePaths.SURVEY]: {
    path: '/survey/:requestId',
    getUrl: (requestId: string) => `/survey/${requestId}`,
  },
  [RoutePaths.BOOKING_CANCELLATION]: {
    path: '/booking/cancellation/:token',
    getUrl: (token: string) => `/booking/cancellation/${token}`,
  },
};

