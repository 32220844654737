import axios from 'modules/api-client/request';
import { handleAmazonMessage } from 'modules/api-client/response';

import type {
  ILoginRequest,
  ISignUpRequest,
  ILoginResponse,
  ISignUpResponse,
  IEmailConfirmRequest,
  IRefreshTokenRequest,
  IUpdatePasswordRequest,
  IChangePasswordRequest,
  IChangePasswordResponse,
  IUpdatePasswordResponse,
  IGetCurrentUserResponse,
  IRecoveryPasswordRequest,
  ILoginUpdatePasswordError,
  IRecoveryPasswordResponse,
  IConfirmRecoveryPasswordRequest,
  IUpdateProfileAttributesRequest,
  IUpdateProfileAttributesResponse,
  IConfirmRecoveryPasswordResponse,
} from './types';

export const loginRequest = (data: ILoginRequest): Promise<ILoginResponse | ILoginUpdatePasswordError> => {
  return axios.post<ILoginRequest, ILoginResponse | ILoginUpdatePasswordError>('/customers/signin', data);
};

export function isChangePasswordSuccess(response: IUpdatePasswordResponse | ILoginUpdatePasswordError): response is IUpdatePasswordResponse {
  return (response as IUpdatePasswordResponse).AuthenticationResult !== undefined;
}

export const updatePasswordRequest = (data: IUpdatePasswordRequest) => {
  return axios.post<IUpdatePasswordRequest, IUpdatePasswordResponse | ILoginUpdatePasswordError>('/customers/signin', data);
}

export const signUpRequest = (data: ISignUpRequest): Promise<ISignUpResponse> => {
  return axios.post<ISignUpRequest, ISignUpResponse>('/customers/signup', data)
    .catch(handleAmazonMessage);
};

export const confirmEmailRequest = (data: IEmailConfirmRequest): Promise<void> => {
  return axios.post<IEmailConfirmRequest, void>('/customers/confirm', data)
    .catch(handleAmazonMessage);
};

export const getCurrentUserRequest = (): Promise<IGetCurrentUserResponse> => {
  return axios.get<void, IGetCurrentUserResponse>('/customers/me')
    .catch(handleAmazonMessage);
};

export const refreshTokenRequest = (data: IRefreshTokenRequest) => {
  return axios.post<IRefreshTokenRequest, ILoginResponse>('/customers/signin', data)
    .catch(handleAmazonMessage);
};

export const changePasswordRequest = (data: IChangePasswordRequest) => {
  return axios.post<IChangePasswordRequest, IChangePasswordResponse>('/customers/change-password', data)
    .catch(handleAmazonMessage);
};

export const updateProfileAttributesRequest = (data: IUpdateProfileAttributesRequest) => {
  return axios.put<IUpdateProfileAttributesRequest, IUpdateProfileAttributesResponse>('/customers/update-attributes', data)
    .catch(handleAmazonMessage);
};

export const recoveryPasswordRequest = (data: IRecoveryPasswordRequest) => {
  return axios.post<IRecoveryPasswordRequest, IRecoveryPasswordResponse>('/customers/forgot-password', data);
}

// we use this request to confirm password after recovery
export const confirmRecoveryPasswordRequest = (data: IConfirmRecoveryPasswordRequest) => {
  return axios.post<IConfirmRecoveryPasswordRequest, IConfirmRecoveryPasswordResponse>('/customers/confirm-password', data)
    .catch(handleAmazonMessage);
}
