import styled from 'styled-components';
import Skeleton from 'antd/es/skeleton';

export const CourseName = styled(Skeleton.Input)`
 width: 220px;
 margin-left: 16px;
`;

export const SkeletonButton = styled(Skeleton.Button)`
  border-radius: 12px;
`;
