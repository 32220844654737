import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazyWithRetry } from 'utils/lazy-with-reload';
// components
import { OutsideRoutes } from './outside-routes';
import { PathCreator, RoutePaths } from './constants';
import { AuthSuspense } from 'pages/auth/auth-suspense';
import { ScrollToTop } from '../utils/scroll-to-top';

// dynamic loading pages
const SignUpPage = lazyWithRetry(() => import('pages/auth/signup'));
const LoginPage = lazyWithRetry(() => import('pages/auth/login-page'));
const LogoutPage = lazyWithRetry(() => import('pages/auth/logout-page'));
const PublicRoutes = lazyWithRetry(() => import('routing/public-routes'));
const ConfirmEmailPage = lazyWithRetry(() => import('pages/auth/email-confirmation-page'));
const ChangePasswordPage = lazyWithRetry(() => import('pages/auth/change-password-page'));
const RecoveryPasswordPage = lazyWithRetry(() => import('pages/auth/password-recovery-page'));

export const Routing = () => (
  <Suspense fallback={<AuthSuspense />}>
    <ScrollToTop />
    <Switch>
      <Route path={PathCreator[RoutePaths.AUTH_CONFIRM_EMAIL].path} component={ConfirmEmailPage} exact />
      <Route path={PathCreator[RoutePaths.AUTH_CHANGE_PASSWORD].path} component={ChangePasswordPage} exact />
      <Route path={PathCreator[RoutePaths.AUTH_RECOVERY_PASSWORD].path} component={RecoveryPasswordPage} exact />
      <Route path={PathCreator[RoutePaths.AUTH_SIGNUP].path} component={SignUpPage} exact />
      <Route path={PathCreator[RoutePaths.AUTH_LOGIN].path} component={LoginPage} exact />
      <Route path={PathCreator[RoutePaths.AUTH_LOGOUT].path} component={LogoutPage} exact />
      <Route path={PathCreator[RoutePaths.SURVEY].path} component={OutsideRoutes} />

      <Route path="/" component={PublicRoutes} />
    </Switch>
  </Suspense>
);

