import set from 'lodash/set';
import { createAction, createReducer } from '@reduxjs/toolkit';

import type { PropertyPath } from 'lodash';
import type { IBookingForm, IBookingState } from './types';

// { list, item, form}
export const bookingInitState: IBookingState = {
  payment: {
    price: 0,
    time: '',
    date: '',
    players: 1,
    tradeRound: false,
    teeTime: undefined,
    reference: undefined,
  },
  form: {
    playersNumber: undefined,
    acceptTerms: false,
    players: [
      { firstName: undefined, lastName: undefined, phone: undefined, email: undefined },
    ],
    rates: {},
    useTradeRound: false,
    coupon: {
      note: undefined,
      acceptedPromoCode: undefined,
    },
    error: undefined,
  },
};

export const bookingActions = {
  setFormFields: createAction<IBookingForm>('booking/form/setFields'),
  setFormPathField: createAction<{ path: PropertyPath, value: unknown }>('booking/form/setPathField'),
  updateFormFields: createAction<Record<string, unknown>>('booking/form/updateFields'),
  resetFormFields: createAction('booking/form/resetFields'),
  setPaymentInfo: createAction<typeof bookingInitState.payment>('booking/setPaymentInfo'),
};

export const bookingReducer = createReducer(
  bookingInitState,
  (builder) => {
    builder
      .addCase(bookingActions.setFormFields, (state, { payload }) => ({
        ...state,
        form: payload,
      }))
      .addCase(bookingActions.updateFormFields, (state, { payload }) => ({
        ...state,
        form: {
          ...state.form,
          ...payload
        },
      }))
      .addCase(bookingActions.setFormPathField, (state, { payload: { path, value } }) => {
        set(state, path, value);
      })
      .addCase(bookingActions.resetFormFields, () => bookingInitState)
      .addCase(bookingActions.setPaymentInfo, (state, { payload }) => ({
        ...state,
        payment: payload,
      }))
  },
);
